

































import {auth} from '../db.js'

export default
  name: "Drawer"
  props: ["visible"]
  
  data:->
    show: true
    user: auth.currentUser
  methods:
    home:-> @$router.push "/"
    fact:-> @$router.push "/fact"
      
    logout:->
      auth.signOut()
      @$router.go path: @$router.path

