<template lang="pug">

v-dialog(v-model="dialog" persistent max-width="600px")
  v-card
    v-card-title
      span.headline Вход
    v-card-text
      v-container
        v-row
          v-col(cols="12")
            v-text-field(label="Email*" required v-model="email")
          v-col(cols="12")
            v-text-field(label="Password*" type="password" required v-model="password")
      small *indicates required field
    v-card-actions
      v-spacer
      v-btn(color="blue darken-1" text @click="doLogin") Login
</template>

<script>

import {auth} from '../db.js'

export default {
    name: 'login',
    data() {
        return {
            dialog:false,
            email: null,
            password: null
        }
    },
    mounted() {
        this.dialog = true;
    },
    methods: {
        doLogin() {
            auth
            .signInWithEmailAndPassword(this.email, this.password)
            .then( 
                ()=> {
                    this.$router.go({ path: this.$router.path })
                },
                err => {
                    alert(err.message)
                }
            )
        }
    }
}
</script>

<style>

</style>
